import React from 'react';
import { graphql } from 'gatsby';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const TermOfUse: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground>
        <PageTitle title="Term Of Use" subtitle="" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">Welcome to the Ironhack Website</Text3>
          <Text3 as="p">
            Welcome to the Ironhack Website – and thank you for visiting. We
            hope you enjoy the experience!
          </Text3>
          <Text3 as="p">
            Ironhack, Inc. (“<strong>Ironhack</strong>”, “<strong>us</strong>”,
            “<strong>we</strong>” or “<strong>our</strong>”) is an international
            provider of courses in Web Development and UX/UI Design with
            locations throughout the world. These Terms of Use (“
            <strong>Terms</strong>”) are a legal contract between you (“
            <strong>you</strong>”, “<strong>your</strong>”)and us and govern
            your use of all the text, data, information, software, graphics,
            photographs and more (all of which we refer to as “
            <strong>Materials</strong>”) that we and our affiliates may make
            available to you, as well as any services (“Services”) we may
            provide through our website (all of which are referred to in these
            Terms as this “<strong>Website</strong>”).
          </Text3>
          <Text3 as="p">
            READ THESE TERMS CAREFULLY BEFORE BROWSING THIS WEBSITE. USING THIS
            WEBSITE INDICATES THAT YOU HAVE BOTH READ AND ACCEPT THESE TERMS.
            YOU CANNOT USE THIS WEBSITE IF YOU DO NOT ACCEPT THESE TERMS.
          </Text3>
          <Text3 as="p">
            NOTE: THESE TERMS CONTAIN A DISPUTE RESOLUTION AND ARBITRATION
            PROVISION, INCLUDING CLASS ACTION WAIVER THAT AFFECTS YOUR RIGHTS
            UNDER THESE TERMS AND WITH RESPECT TO DISPUTES YOU MAY HAVE WITH THE
            COMPANY. YOU MAY OPT OUT OF THE BINDING INDIVIDUAL ARBITRATION AND
            CLASS ACTION WAIVER AS PROVIDED BELOW.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">CHANGES</Text2>
          <Text3 as="p">
            We may alter the Materials and Services we offer you and/or choose
            to modify, suspend or discontinue this Website at any time and
            without notifying you. We may also change, update, add or remove
            provisions (collectively, “modifications”) of these Terms from time
            to time. Because everyone benefits from clarity, we promise to
            inform you of any modifications to these Terms by posting them on
            this Website.
          </Text3>
          <Text3 as="p">
            If you object to any such modifications, your sole recourse shall be
            to cease using this Website. Continued use of this Website following
            notice of any such modifications indicates you acknowledge and agree
            to be bound by the modifications. Also, please know that these Terms
            may be superseded by expressly-designated legal notices or terms
            located on particular pages of this Website. These
            expressly-designated legal notices or terms are incorporated into
            these Terms and to supersede the provision(s) of these Terms that
            are designated as being superseded.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">GENERAL USE</Text2>
          <Text3 as="p">
            By using this Website, you promise that you are at least 18 years of
            age. If you are not yet 18 years old, you must have the permission
            of an adult to use this Website and agree to its Terms, and that
            adult must be a parent or legal guardian who is willing to be
            responsible for your use of this Website.
          </Text3>
          <Text3 as="p">
            We invite you to use this Website for individual, consumer purposes
            (“<strong>Permitted Purposes</strong>”) – enjoy!
          </Text3>
          <Text3 as="p">
            In these Terms we are granting you a limited, personal,
            non-exclusive and non-transferable license to use and to display the
            Materials; Your right to use the Materials is conditioned on Your
            compliance with these Terms. You have no other rights in this
            Website or any Materials and you may not modify, edit, copy,
            reproduce, create derivative works of, reverse engineer, alter,
            enhance or in any way exploit any of this Website or Materials in
            any manner. If you make copies of any of this Website while engaging
            in Permitted Purposes then we ask that you be sure to keep on the
            copies all of our copyright and other proprietary notices as they
            appear on this Website.
          </Text3>
          <Text3 as="p">
            Unfortunately, if you breach any of these Terms the above license
            will terminate automatically and you must immediately destroy any
            downloaded or printed Materials (and any copies thereof).
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">USING THIS WEBSITE AND OUR SERVICES</Text2>
          <Text3 as="p">
            We appreciate you visiting this Website and allow you to do just
            that – stop by and leisurely check it out without even applying to
            us!
          </Text3>
          <Text3 as="p">
            However, in order to access certain Services, our instructors, and
            specialized course materials, you must first apply, then be accepted
            into one of our Web Development or UX/UI Design Bootcamps or part
            time programs (“<strong>Ironhack Bootcamp</strong>”).
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IRONHACK REGISTRATION</Text2>
          <Text3 as="p">
            If you want to register for an Ironhack Bootcamp, you must submit
            the following information after clicking on the “
            <a href="https://www.ironhack.com/en/courses/apply">Apply now</a>”
            (Register) button on this website:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Name and surname;</li>
              <li>Telephone number;</li>
              <li>An active e-mail address;</li>
              <li>Gender;</li>
              <li>Reference; and</li>
              <li>
                A brief description of why you are interested in Ironhack.
              </li>
            </ul>
            During the registration process you may also provide additional
            optional information so we can provide you a more personalised
            experience when using this Web Site, however, we leave that up to
            you. Once you have submitted the requested information, we will
            determine if we accept your registration. If approved, you will
            receive an email detailing the steps necessary to complete the
            registration process. If you do not submit the information listed
            above, we will not be able to process your registration form or
            provide you with our services in the most appropriate manner.
          </Text3>
          <Text3 as="p">
            You are responsible for complying with these Terms when you access
            this Web Site. It is your responsibility to obtain and maintain the
            equipment and technical services necessary to access and use this
            Website and to pay any associated charges. It is also your
            responsibility to maintain the confidentiality of the information
            you provide to us during your registration for Bootcamp Ironhack. If
            you believe that your personal information or security with respect
            to this Website has been breached in any way, you must notify us
            immediately. If you need more information, please visit the{' '}
            <a href="https://www.ironhack.com/en/legal/privacy-policy">
              Privacy Policy
            </a>
            .
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ELECTRONIC COMMUNICATIONS</Text2>
          <Text3 as="p">
            By using the Website, you consent to receiving electronic
            communications from us, including via e-mail. These electronic
            communications may include notices about applicable fees and
            charges, transactional information and other information concerning
            or related to the Website or our courses. These electronic
            communications are part of your relationship with us. You agree that
            any notices, agreements, disclosures or other communications that we
            send you electronically will satisfy any legal communication
            requirements, including that such communications be in writing.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">PRIVACY POLICY</Text2>
          <Text3 as="p">
            We respect the information that you provide to us, and want to be
            sure you fully understand exactly how we use that information. So,
            please review our “<strong>Privacy Policy</strong>” which explains
            everything.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LINKS TO THIRDPARTY SITES</Text2>
          <Text3 as="p">
            You may want to follow us, connect with us on social media, or
            access other related web pages referenced on our blog page or
            Website. We think links are convenient, and we sometimes provide
            links on this Website to third-party websites. If you use these
            links, you will leave this Website. We are not obligated to review
            any third-party websites that you link to from this Website, we do
            not control any of the third-party websites, and we are not
            responsible for any of the third-party websites (or the products,
            services, or content available through any of them). Thus, we do not
            endorse or make any representations about such third-party websites,
            any information, software, products, Services or Materials found
            there or any results that may be obtained from using them. If you
            decide to access any of the third-party websites linked to from this
            Website, you do this entirely at your own risk and you must follow
            the privacy policies and terms and conditions for those separate
            third-party websites.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">SUBMISSIONS</Text2>
          <Text3 as="p">
            Certain areas of this Website (e.g., the blog comment feature) may
            permit you to submit Feedback, information, data, text, messages, or
            other materials (each, a “<strong>User Submission</strong>”). You
            agree that you are solely responsible for all of your User
            Submissions and that any such User Submission is considered both
            non-confidential and non-proprietary. Further, we do not guarantee
            that you will be able to edit or delete any User Submission you have
            submitted.
          </Text3>
          <Text3 as="p">
            By submitting any User Submission, you are promising us that:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                You own all rights in Your User Submissions (including, without
                limitation, all rights to the reproduction and display of your
                User Submissions) or, alternatively, you have acquired all
                necessary rights in your User Submissions to enable you to grant
                to us the rights in your User Submissions as described in these
                Terms;
              </li>
              <li>
                You have paid and will pay in full all license fees, clearance
                fees, and other financial obligations, of any kind, arising from
                any use or commercial exploitation of your User Submissions;
              </li>
              <li>
                Your User Submissions do not infringe the copyright, trademark,
                patent, trade secret, or other intellectual property rights,
                privacy rights, or any other legal or moral rights of any third
                party;
              </li>
              <li>
                You voluntarily agree to waive all 'moral rights' that you may
                have in your User Submission;
              </li>
              <li>
                Any information contained in your User Submission is not known
                by you to be false, inaccurate, or misleading;
              </li>
              <li>
                Your User Submission does not violate any law (including, but
                not limited to, those governing export control, consumer
                protection, unfair competition, anti-discrimination, or false
                advertising);
              </li>
              <li>
                Your User Submission is not, and may not reasonably be
                considered to be, defamatory, libelous, hateful, racially,
                ethnically, religiously, or otherwise biased or offensive,
                unlawfully threatening, or unlawfully harassing to any
                individual, partnership, or corporation, vulgar, pornographic,
                obscene, or invasive of another's privacy;
              </li>
              <li>
                You were not and will not be compensated or granted any
                consideration by any third party for submitting your User
                Submission;
              </li>
              <li>
                Your User Submission does not incorporate materials from a
                third-party website, or addresses, email addresses, contact
                information, or phone numbers (other than your own);
              </li>
              <li>
                Your User Submission does not contain any viruses, worms,
                spyware, adware, or other potentially damaging programs or
                files;
              </li>
              <li>
                Your User Submission does not contain any information that you
                consider confidential, proprietary, or personal; and
              </li>
              <li>
                Your User Submission does not contain or constitute any
                unsolicited or unauthorized advertising, promotional materials,
                junk mail, spam, chain letters, pyramid schemes, or any other
                form of solicitation.
              </li>
            </ul>
            By submitting a User Submission, You grant to us an irrevocable,
            perpetual, transferable, non-exclusive, fully-paid, worldwide,
            royalty-free license (sublicensable through multiple tiers) to:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Use, distribute, reproduce, modify, adapt, publish, translate,
                publicly perform, and publicly display your User Submissions (or
                any modification thereto), in whole or in part, in any format or
                medium now known or later developed;
              </li>
              <li>
                Use (and permit others to use) your User Submission in any
                manner and for any purpose (including, without limitation,
                commercial purposes) that we deem appropriate in our sole
                discretion (including, without limitation, to incorporate your
                User Submission or any modification thereto, in whole or in
                part, into any technology, product, or service);
              </li>
              <li>
                Display advertisements in connection with your User Submissions
                and to use your User Submissions for advertising and promotional
                purposes.
              </li>
            </ul>
            We do not tolerate any harmful or offensive User Submissions on our
            Website. We may, but are not obligated to, pre-screen User
            Submissions or monitor any area of this Website through which User
            Submissions may be submitted. We are not required to host, display,
            or distribute any User Submissions on or through this Website and
            may remove at any time or refuse any User Submissions for any
            reason. We are not responsible for any loss, theft, or damage of any
            kind to any User Submissions. Further, you agree that we may freely
            disclose your User Submission to any third party absent any
            obligation of confidence on the part of the recipient.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">UNAUTHORIZED ACTIVITIES</Text2>
          <Text3 as="p">
            To be clear, we authorize your use of this Website only for
            Permitted Purposes. Any other use of this Website beyond the
            Permitted Purposes is prohibited and, therefore, constitutes
            unauthorized use of this Website. This is because as between you and
            us, all rights in this Website remain our property.
          </Text3>
          <Text3 as="p">
            Unauthorized use of this Website may result in violation of various
            United States and international copyright laws. Because we prefer
            keeping this relationship drama-free, we want to give you examples
            of things to avoid. So, unless you have written permission from us
            stating otherwise, you are not authorized to use this Website in any
            of the following ways (these are examples only and the list below is
            not a complete list of everything that you are not permitted to do):
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                For any public or commercial purpose which includes use of this
                Website on another site or through a networked computer
                environment;
              </li>
              <li>
                In a manner that modifies, publicly displays, publicly performs,
                reproduces or distributes any of this Website;
              </li>
              <li>
                In a manner that violates any local, state, national, foreign,
                or international statute, regulation, rule, order, treaty, or
                other law;
              </li>
              <li>To stalk, harass, or harm another individual;</li>
              <li>
                To impersonate any person or entity or otherwise misrepresent
                your affiliation with a person or entity;
              </li>
              <li>
                To interfere with or disrupt this Website or servers or networks
                connected to this Website;
              </li>
              <li>
                To use any data mining, robots, or similar data gathering or
                extraction methods in connection with this Website; or
              </li>
              <li>
                Attempt to gain unauthorized access to any portion of this
                Website or any other accounts, computer systems, or networks
                connected to this Website, whether through hacking, password
                mining, or any other means.
              </li>
            </ul>
            You agree to hire attorneys to defend us if you violate these Terms
            and that violation results in a problem for us. You also agree to
            pay any damages that we may end up having to pay as a result of your
            violation. You alone are responsible for any violation of these
            Terms by you. We reserve the right to assume the exclusive defense
            and control of any matter otherwise subject to indemnification by
            you and, in such case, you agree to cooperate with our defense of
            such claim.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">PROPRIETARY RIGHTS</Text2>
          <Text3 as="p">
            “Ironhack” is a trademark that belongs to us. Other trademarks,
            names and logos on this Website are the property of their respective
            owners.
          </Text3>
          <Text3 as="p">
            Unless otherwise specified in these Terms, all Materials, including
            the arrangement of them on this Website are our sole property,
            Copyright © 2018. All rights not expressly granted herein are
            reserved. Except as otherwise required or limited by applicable law,
            any reproduction, distribution, modification, retransmission, or
            publication of any copyrighted material is strictly prohibited
            without the express written consent of the copyright owner or
            license.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INTELLECTUAL PROPERTY INFRINGEMENT</Text2>
          <Text3 as="p">
            We respect the intellectual property rights of others and encourage
            you to do the same. Accordingly, we have a policy of removing User
            Submissions that violate intellectual property rights of others,
            suspending access to this Website (or any portion thereof) to any
            user who uses this Website in violation of someone’s intellectual
            property rights, and/or terminating in appropriate circumstances the
            Ironhack Bootcamp of any user who uses this Website in violation of
            someone’s intellectual property rights.
          </Text3>
          <Text3 as="p">
            Pursuant to Title 17 of the United States Code, Section 512, we have
            implemented procedures for receiving written notification of claimed
            copyright infringement and for processing such claims in accordance
            with such law. If you believe your copyright or other intellectual
            property right is being infringed by a user of this Website, please
            provide written notice to Ironhack for notice of claims of
            infringement:
          </Text3>
          <Text3 as="p">Attn: Ironhack DMCA Agent</Text3>
          <Text3 as="p">
            Email: <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
          <Text3 as="p">
            To be sure the matter is handled immediately, Your written notice
            must:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Contain Your physical or electronic signature;</li>
              <li>
                Identify the copyrighted work or other intellectual property
                alleged to have been infringed;
              </li>
              <li>
                Identify the allegedly infringing material in a sufficiently
                precise manner to allow us to locate that material;
              </li>
              <li>
                Contain adequate information by which we can contact you
                (including postal address, telephone number, and e-mail
                address);
              </li>
              <li>
                Contain a statement that you have a good faith belief that use
                of the copyrighted material or other intellectual property is
                not authorized by the owner, the owner's agent or the law;
              </li>
              <li>
                Contain a statement that the information in the written notice
                is accurate; and
              </li>
              <li>
                Contain statement, under penalty of perjury, that you are
                authorized to act on behalf of the copyright or other
                intellectual property right owner.
              </li>
            </ul>
            Unless the notice pertains to copyright or other intellectual
            property infringement, the Ironhack DMCA Agent will be unable to
            address the listed concern.
          </Text3>
          <Text3 as="p">Submitting a DMCA Counter-Notification</Text3>
          <Text3 as="p">
            We will notify you that we have removed or disabled access to
            copyright-protected material that you provided, if such removal is
            pursuant to a validly received DMCA take-down notice. In response,
            you may provide the Ironhack DMCA Agent with a written
            counter-notification that includes the following information:
          </Text3>
          <Text3 as="p">
            <ol>
              <li>Your physical or electronic signature;</li>
              <li>
                Identification of the material that has been removed or to which
                access has been disabled, and the location at which the material
                appeared before it was removed or access to it was disabled;
              </li>
              <li>
                A statement from you under the penalty of perjury, that you have
                a good faith belief that the material was removed or disabled as
                a result of a mistake or misidentification of the material to be
                removed or disabled; and
              </li>
              <li>
                Your name, physical address and telephone number, and a
                statement that you consent to the jurisdiction of a court for
                the judicial district in which your physical address is located,
                or if your physical address is outside of the United States, for
                any judicial district in which we may be located, and that you
                will accept service of process from the person who provided
                notification of allegedly infringing material or an agent of
                such person.
              </li>
            </ol>
            Termination of Repeat Infringers
          </Text3>
          <Text3 as="p">
            We reserve the right, in our sole discretion, to terminate an
            Ironhack Bootcamp or access of any user of this Website or Service
            who is the subject of repeated DMCA or other infringement
            notifications. Notices and counter-notices must meet the
            then-current statutory requirements imposed by the DMCA; see
            https://www.copyright.gov/ for details. Consult your legal advisor
            and see 17 U.S.C. § 512 before filing a notice or counter-notice as
            there are penalties for false claims under the DMCA.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DISCLAIMER OF WARRANTIES</Text2>
          <Text3 as="p">
            THIS WEBSITE IS PROVIDED 'AS IS' AND 'WITH ALL FAULTS' AND THE
            ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THIS WEBSITE IS
            WITH YOU.
          </Text3>
          <Text3 as="p">
            WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND (EXPRESS, IMPLIED
            OR STATUTORY) WITH RESPECT TO THIS WEBSITE, WHICH INCLUDES BUT IS
            NOT LIMITED TO, ANY IMPLIED OR STATUTORY WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, AND
            NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS.
          </Text3>
          <Text3 as="p">
            THIS MEANS THAT WE DO NOT PROMISE YOU THAT THE WEBSITE IS FREE OF
            PROBLEMS. Without limiting the generality of the foregoing, we make
            no warranty that this Website will meet your requirements or that
            this Website will be uninterrupted, timely, secure, error free, or
            that defects in this Website will be corrected.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">OUTCOMES</Text2>
          <Text3 as="p">
            We make no warranty as to the results that may be obtained from the
            use of this Website or as to the accuracy or reliability of any
            information obtained through this Website. No advice or information,
            whether oral or written, obtained by You through this Website or
            from us or our subsidiaries/other affiliated companies shall create
            any warranty. We disclaim all equitable indemnities.
          </Text3>
          <Text3 as="p">
            We endeavor to recruit top instructors and staff to give you the
            best Ironhack Bootcamp experience. We may feature a particular
            Ironhack Bootcamp instructor as an advertisement, but this is not a
            recommendation of such specific instructor or a guarantee that your
            course will be led by such instructor. We make no representations as
            to the quality or nature of any specific Ironhack Bootcamp
            instructor.
          </Text3>
          <Text3 as="p">
            From time to time, we may publicize and promote the outcomes of
            certain featured alumni from the Ironhack Bootcamp on this Website
            and in our social media or marketing outlets. In no case does
            Ironhack guarantee any outcome, any job, any salary or any
            particular vocational end. The Ironhack Bootcamp is not a job
            placement program, and certain results may not be replicated. We
            strive to be completely transparent with our presentation of
            testimonials by Ironhack alumni, prior outcomes and results,
            including average ratings and exceptions. It is our goal to give
            Ironhack Bootcamp participants the data to understand that past
            results are not predictors of future results.
          </Text3>
          <Text3 as="p">
            IF AN IRONHACK ALUMNI LANDED A GREAT JOB AFTER COMPLETING AN
            IRONHACK BOOTCAMP, THAT FACT IS NOT A GUARANTEE THAT YOU WILL HAVE
            THE SAME OUTCOME. OUTCOMES ARE HIGHLY VARIABLE BY LOCATION AND A
            FUNCTION OF MARKET CONDITIONS BEYOND OUR CONTROL. WE DO NOT
            GUARANTEE ANY OUTCOME.
          </Text3>
          <Text3 as="p">
            Completing an Ironhack Bootcamp does not guarantee that you will
            become proficient in the subject matter taught in the Ironhack
            Bootcamp. Our instructors and staff work to guide learning
            throughout the Ironhack Bootcamp, but some students do not succeed.
            Some students who graduate the Ironhack Bootcamp do not become
            employed in the field. Some students do not graduate, and some
            students do not become skilled or capable in the subject matter
            taught in the Ironhack Bootcamp. You should speak to an Ironhack
            Program Manager about the different locations and timings, admission
            requirements, all associated fees and costs, and your capacity
            before enrolling in any Ironhack Bootcamp.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LIMITATION OF LIABILITY</Text2>
          <Text3 as="p">
            WE SHALL NOT BE LIABLE TO YOU FOR ANY DAMAGES RESULTING FROM YOUR
            DISPLAYING, COPYING, OR DOWNLOADING ANY MATERIALS TO OR FROM THIS
            WEBSITE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
            EVENT SHALL WE BE LIABLE TO YOU FOR ANY INDIRECT, EXTRAORDINARY,
            EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES
            (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC
            ADVANTAGE) HOWEVER ARISING, EVEN IF WE KNOW THERE IS A POSSIBILITY
            OF SUCH DAMAGE.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LOCAL LAWS EXPORT CONTROL</Text2>
          <Text3 as="p">
            We control and operate this Website from our headquarters in the
            United States of America and the entirety of this Website may not be
            appropriate or available for use in other locations. If you use this
            Website outside the United States of America, you are solely
            responsible for following applicable local laws.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">FEEDBACK</Text2>
          <Text3 as="p">
            Any submissions by you to us (e.g., comments, testimonials, quotes,
            questions, suggestions, materials – collectively, “
            <strong>Feedback</strong>”) through any communication whatsoever
            (e.g., call, blog comments, email) will be treated as both
            non-confidential and non-proprietary. Except as prohibited by
            applicable law, you hereby assign all right, title, and interest in,
            and we are free to use, without any attribution or compensation to
            You, any ideas, know-how, concepts, techniques, or other
            intellectual property and proprietary rights contained in the
            Feedback, whether or not patentable, for any purpose whatsoever,
            including but not limited to, developing, manufacturing, having
            manufactured, licensing, marketing, and selling, directly or
            indirectly, products and services using such Feedback. Where the
            foregoing assignment is prohibited by law, you hereby grant us an
            exclusive, transferable, worldwide, royalty-free, fully paid up
            license (including the right to sublicense) to use and exploit all
            Feedback as we may determine in our sole discretion. Notwithstanding
            the foregoing, you understand and agree that we are not obligated to
            use, display, reproduce, or distribute any such ideas, know-how,
            concepts, or techniques contained in the Feedback, and you have no
            right to compel such use, display, reproduction, or distribution.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            DISPUTE RESOLUTION AND ARBITRATION CLASS ACTION WAIVER
          </Text2>
          <Text3 as="p">
            Please Read This Provision Carefully. It Affects Your Legal Rights.
          </Text3>
          <Text3 as="p">
            This Dispute Resolution and Arbitration; Class Action Waiver
            provision (this “<strong>Provision</strong>”) facilitates the prompt
            and efficient resolution of any dispute (e.g., claim or controversy,
            whether based in contract, statute, regulation, ordinance, tort –
            including, but not limited to, fraud, misrepresentation, fraudulent
            inducement, or negligence – or any other legal or equitable theory,
            and includes the validity, enforceability or scope of this Provision
            (with the exception of the enforceability of the Class Action Waiver
            clause below) that may arise between you and us. Effectively, then,
            “dispute” is given the broadest meaning enforceable by law and
            includes any claims against other parties relating to the services
            or products provided or billed to you (such as our licensors,
            suppliers, dealers or third-party vendors) whenever you also assert
            claims against us in the same proceeding.
          </Text3>
          <Text3 as="p">
            This Provision provides that all disputes between you and us shall
            be resolved by binding arbitration because acceptance of these Terms
            constitutes a waiver of your right to litigation claims and all
            opportunity to be heard by a judge or jury. We prefer this because
            we believe arbitration is more efficient and less contentious than
            litigation. To be clear, there is no judge or jury in arbitration,
            and court review of an arbitration award is limited. The arbitrator
            must follow this agreement and can award the same damages and relief
            as a court (including attorney’s fees). You may, however, opt-out of
            this provision which means you would have a right or opportunity to
            bring claims in a court, before a judge or jury, and/or to
            participate in or be represented in a case filed in court by others
            (including, but not limited to, class actions). YOU AND WE BOTH
            AGREE THAT, EXCEPT AS PROVIDED BELOW, ANY AND ALL DISPUTES, AS
            DEFINED ABOVE, WHETHER PRESENTLY IN EXISTENCE OR BASED ON ACTS OR
            OMISSIONS IN THE PAST OR IN THE FUTURE, WILL BE RESOLVED EXCLUSIVELY
            AND FINALLY BY BINDING ARBITRATION RATHER THAN IN COURT IN
            ACCORDANCE WITH THIS PROVISION.
          </Text3>
          <Text3 as="p">Pre-Arbitration Claim Resolution</Text3>
          <Text3 as="p">
            For all disputes, whether pursued in court or arbitration, you must
            first give us an opportunity to resolve the dispute which is first
            done by emailing us at&nbsp;
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>, and
            including the following information: (1) your name, (2) your
            address, (3) a written description of your claim and the basis of
            your dispute, and (4) a description of the specific relief you seek.
            If we do not resolve your dispute within forty-five (45) days after
            receiving your notification, than you may pursue your dispute in
            arbitration. You may pursue your dispute in a court only under the
            circumstances described below.
          </Text3>
          <Text3 as="p">Exclusions from Arbitration/Right to Opt Out</Text3>
          <Text3 as="p">
            Notwithstanding the above, you or we may choose to pursue a dispute
            in court and not by arbitration if: (a) the dispute qualifies for
            initiation in small claims court; or (b) YOU OPT-OUT OF THESE
            ARBITRATION PROCEDURES WITHIN 30 DAYS FROM THE DATE THAT YOU FIRST
            CONSENT TO THIS AGREEMENT (the “<strong>Opt-Out Deadline</strong>”).
            You may opt-out of this arbitration provision by emailing us
            at&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a> the
            following information: (1) your name; (2) your address; (3) a clear
            statement that you do not wish to resolve disputes with us through
            arbitration. Either way, we will not take any decision you make
            personally. In fact, we promise that your decision to opt-out of
            this arbitration provision will have no adverse effect on your
            relationship with us. But, we do have to enforce the Opt-Out
            Deadline so keep in mind that&nbsp;
            <strong>
              any opt-out request received after the Opt-Out Deadline will not
              be valid and you must pursue your dispute in arbitration or small
              claims court
            </strong>
            .
          </Text3>
          <Text3 as="p">Arbitration Procedures</Text3>
          <Text3 as="p">
            If this Provision applies and the dispute is not resolved as
            provided above (Pre-Arbitration Claim Resolution) either you or we
            may initiate arbitration proceedings. The American Arbitration
            Association (“<strong>AAA</strong>”), www.adr.org, or JAMS,
            www.jamsadr.com, will arbitrate all disputes, and the arbitration
            will be conducted before a single arbitrator. The arbitration shall
            be commenced as an individual arbitration only, and shall in no
            event be commenced as a class arbitration or a consolidated or
            representative action or arbitration. All issues shall be for the
            arbitrator to decide, including the scope of this arbitration
            provision.
          </Text3>
          <Text3 as="p">
            For arbitration before AAA, for disputes of less than $75,000, the
            AAA’s Supplementary Procedures for Consumer-Related Disputes will
            apply; for disputes involving $75,000 or more, the AAA’s Commercial
            Arbitration Rules will apply. In either instance, the AAA’s Optional
            Rules For Emergency Measures Of Protection shall apply. The AAA
            rules are available at www.adr.org or by calling 1-800-778-7879. For
            arbitration before JAMS, the JAMS Comprehensive Arbitration Rules
            &amp; Procedures and the JAMS Recommended Arbitration Discovery
            Protocols For Domestic, Commercial Cases will apply. The JAMS rules
            are available at www.jamsadr.com or by calling 1-800-352-5267. This
            Provision governs in the event it conflicts with the applicable
            arbitration rules. Under no circumstances will class action or
            representative procedures or rules apply to the arbitration.
          </Text3>
          <Text3 as="p">
            Because this Website and these Terms concern interstate commerce,
            the Federal Arbitration Act (“<strong>FAA</strong>”) governs the
            arbitrability of all disputes. However, the arbitrator will apply
            applicable substantive law consistent with the FAA and the
            applicable statute of limitations or condition precedent to suit.
          </Text3>
          <Text3 as="p">
            <em>Arbitration Award</em> – The arbitrator may award on an
            individual basis any relief that would be available pursuant to
            applicable law, and will not have the power to award relief to,
            against or for the benefit of any person who is not a party to the
            proceeding. The arbitrator will make any award in writing but need
            not provide a statement of reasons unless requested by a party or if
            required by applicable law. Such award will be final and binding on
            the parties, except for any right of appeal provided by the FAA or
            other applicable law, and may be entered in any court having
            jurisdiction over the parties for purposes of enforcement.
          </Text3>
          <Text3 as="p">
            <em>Location of Arbitration</em> – You or we may initiate
            arbitration in either Florida or the federal judicial district that
            includes your billing address.
          </Text3>
          <Text3 as="p">
            <em>Payment of Arbitration Fees and Costs</em> – So long as you
            place a request in writing prior to commencement of the arbitration,
            we will pay all arbitration filing fees and AAA or JAMS hearing fees
            and any arbitrator's hearing fees, costs and expenses upon your
            written request to the arbitrator given at or before the first
            evidentiary hearing in the arbitration. But, you will still be
            responsible for all additional fees and costs that you incur in the
            arbitration which include but are not limited to attorneys’ fees or
            expert witnesses. In addition to any fees and costs recoverable
            under applicable law, if you provide notice and negotiate in good
            faith with us as provided in the section above titled
            “Pre-Arbitration Claim Resolution” and the arbitrator concludes that
            you are the prevailing party in the arbitration, You will be
            entitled to recover reasonable attorney’s fees and costs as
            determined by the arbitrator.
          </Text3>
          <Text3 as="p">Class Action Waiver</Text3>
          <Text3 as="p">
            Except as otherwise provided in this arbitration provision, the
            arbitrator may not consolidate more than one person’s claims, and
            may not otherwise preside over any form of a class or representative
            proceeding or claims (such as a class action, consolidated action,
            representative action, or private attorney general action) unless
            both you and we specifically agree to do so in writing following
            initiation of the arbitration. If you choose to pursue your dispute
            in court by opting out of this arbitration provision, as specified
            above, this Class Action Waiver will not apply to you. Neither you,
            nor any other user of this Website can be a class representative,
            class member, or otherwise participate in a class, consolidated, or
            representative proceeding without having complied with the opt-out
            requirements above.
          </Text3>
          <Text3 as="p">Jury Waiver</Text3>
          <Text3 as="p">
            You understand and agree that by accepting this arbitration
            provision in these Terms, you and we are each waiving the right to a
            jury trial or a trial before a judge in a public court. In the
            absence of this arbitration provision, you and we might otherwise
            have had a right or opportunity to bring disputes in a court, before
            a judge or jury, and/or to participate or be represented in a case
            filed in court by others (including class actions). Except as
            otherwise provided below, those rights are waived. Other rights that
            you would have if you went to court (e.g., the rights to both appeal
            and certain types of discovery) may be more limited or may also be
            waived.
          </Text3>
          <Text3 as="p">Severability</Text3>
          <Text3 as="p">
            If any clause within this arbitration provision (other than the
            Class Action Waiver clause above) is found to be illegal or
            unenforceable, that clause will be severed from this arbitration
            provision whose remainder will be given full force and effect. If
            the Class Action Waiver clause is found to be illegal or
            unenforceable, this entire arbitration provision will be
            unenforceable and the dispute will be decided by a court.
          </Text3>
          <Text3 as="p">Continuation</Text3>
          <Text3 as="p">
            This arbitration provision shall survive the completion of your
            Ironhack Bootcamp with us or our affiliates and your discontinued
            use of this Website. Notwithstanding any provision in these Terms to
            the contrary, we agree that if we make any change to this
            arbitration provision (other than a change to the Notice Address),
            you may reject any such change and require us to adhere to the
            present language in this arbitration provision if a dispute between
            us arises.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LANGUAGE</Text2>
          <Text3 as="p">
            The Parties hereto have expressly required that this agreement and
            all documents and notices relating thereto be drafted in the English
            language.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">GENERAL</Text2>
          <Text3 as="p">
            We think direct communication resolves most issues – if we feel that
            you are not complying with these Terms, we will tell you. We will
            even provide you with recommended necessary corrective action(s)
            because we value this relationship.
          </Text3>
          <Text3 as="p">
            However, certain violations of these Terms, as determined by us, may
            require immediate termination of your access to this Website without
            prior notice to you. The Federal Arbitration Act, Florida state law
            and applicable U.S. federal law, without regard to the choice or
            conflicts of law provisions, will govern these Terms. Foreign laws
            do not apply. Except for disputes subject to arbitration as
            described above, any disputes relating to these Terms or this
            Website will be heard in the courts located in Miami-Dade County,
            Florida. If any of these Terms are deemed inconsistent with
            applicable law, then such shall be interpreted to reflect the
            intentions of the parties, and no other terms will be modified. By
            choosing not to enforced any of these Terms, we are not waiving our
            rights. These Terms are the entire agreement between you and us and,
            therefore, supersede all prior or contemporaneous negotiations,
            discussions or agreements between everyone about this Website. The
            proprietary rights, disclaimer of warranties, representations made
            by you, indemnities, limitations of liability and general provisions
            shall survive any termination of these Terms.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">CALIFORNIA CONSUMER NOTICE</Text2>
          <Text3 as="p">
            Under California Civil Code Section 1789.3, California users are
            entitled to the following consumer rights notice: This Website and
            Service are provided by Ironhack, 990 Biscayne Blvd, Ste. 502, Miami
            FL 33132. If You have a question or complaint regarding the Website
            or Service, please contact us directly at&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>. You may
            also contact us by writing Ironhack, 990 Biscayne Blvd, Ste. 502,
            Miami FL 33132. California residents may reach the Complaint
            Assistance Unit of the Division of Consumer Services of the
            California Department of Consumer Affairs by post at 1625 North
            Market Blvd., Sacramento, CA 95834 or by telephone at (916) 445-1254
            or (800) 952-5210 or Hearing Impaired at TDD (800) 326-2297 or TDD
            (916) 322-1700.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">CONTACT US</Text2>
          <Text3 as="p">
            If you have any questions about these Terms or otherwise need to
            contact us for any reason, You can reach us at&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>.
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "en" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('en')(TermOfUse);
